.content {
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  border-radius: 4px;
  outline: none;
  /* padding: 20px; */
  /* border: 1px solid #ccc; */
  /* background: #fff; */

  color: var(--color);
  background: var(--modal-background);
  border: var(--modal-border);
  /* border: 1px solid var(--lightgray); */
  /* border-radius: 0; */
}

/* @media screen and (min-width: 1120px) {
  .content {
    left: calc(50% - 512px);
    right: calc(50% -  512px);
    top: 8%;
    bottom: 8%;
  }
} */

@media screen and (min-width: 475px) {
  .content {
    left: 16%;
    right: 16%;
    top: 8%;
    bottom: 8%;
  }
}

@media screen and (max-width: 475px) {
  .content {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 0;
  }
}
